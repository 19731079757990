import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const HorizonBTE = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: '0z3x7Dm9wWc',
			url: 'https://www.youtube.com/embed/0z3x7Dm9wWc?si=wKzE2VwB-StBgNlU',
			title: 'How to Add a Retention Wire on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to add or replace the retention wire on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: '2kJGkiyh3BI',
			url: 'https://www.youtube.com/embed/2kJGkiyh3BI?si=wKCYHzJtFyayvG-8',
			title: 'How to Change Domes on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to change the silicone domes on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: 'Piq2S_5hVLc',
			url: 'https://www.youtube.com/embed/Piq2S_5hVLc?si=b0dLdaKkwWIdCMy5',
			title: 'How to Change Receivers on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Learn how to change the receivers on the Horizon Go AX hearing aids by hear.com by following these easy steps.'
		},
		{
			id: '2Lvzfwjr73E',
			url: 'https://www.youtube.com/embed/2Lvzfwjr73E?si=QpchIT94SLaukJSz',
			title: 'How to Change Wax Guards on Horizon Go AX Hearing Aids | hear.com',
			subtitle: 'Change your Horizon Go AX hearing aids wax guards with these easy steps.'
		},
		{
			id: 'EbkFgU0igyk',
			url: 'https://www.youtube.com/embed/EbkFgU0igyk?si=yUUutvJ3scMZAly5',
			title: 'How to clean Horizon Hearing aids | hear.com',
			subtitle: 'Watch this video to learn how to properly clean your Horizon hearing aids by hear.com.'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default HorizonBTE;
